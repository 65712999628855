<template>
    <div>
        <div class="form-group floating-input mt-3 input-group">
            <input type="number" class="form-control" id="apcoAmount" v-model.trim="$v.changedAmount.$model" :placeholder="$t('amount')" @keyup="emitIfValid()" required>
            <label for="apcoAmount">{{$t('amount')}}</label>
            <div class="input-group-append">
                <span class="input-group-text">{{playerCurrencySymbol}}</span>
            </div>
        </div>
        <div class="validation-error">
            <label v-if="!$v.changedAmount.required && $v.changedAmount.$dirty">{{$t('please-enter-your', { field: $t('amount') })}}</label>
            <label v-else-if="!$v.changedAmount.numeric && $v.changedAmount.$dirty">{{$t('not-a-number')}}</label>
            <label v-else-if="!$v.changedAmount.minValue && $v.changedAmount.$dirty">{{$t('min-amount', { value: minDeposit}) }}</label>
            <label v-else-if="!$v.changedAmount.maxValue && $v.changedAmount.$dirty">{{$t('max-amount', { value: maxDeposit}) }}</label>
        </div>

        <p class="alert alert-danger mt-3 text-center" v-if="limitReached">{{limitReached}}</p>
        <p class="alert alert-dark mt-3 text-center">{{$t('deposit-limits')}} {{$t('minimum')}} {{minDeposit}} {{playerCurrencySymbol}}, {{$t('maximum')}} {{maxDeposit}} {{playerCurrencySymbol}}</p>
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate';
    import {required, numeric, minValue, maxValue} from 'vuelidate/lib/validators';

    export default {
        name: 'LimitsAmount',
        props:{
            amount: Number,
            playerCurrencySymbol: String,
            limitReached: String,
            minDeposit: Number,
            maxDeposit: Number,
        },
        data(){
            return{
                changedAmount: this.amount
            }
        },
        mixins: [validationMixin],
        validations() {
            return {
                changedAmount: {
                    required,
                    numeric,
                    minValue: minValue(this.minDeposit),
                    maxValue: maxValue(this.maxDeposit)
                }
            }
        },
        methods:{
            emitIfValid(){
                this.$v.$touch();
                if(this.$v.changedAmount.$error === false){
                    this.$emit('playerAmount', parseInt(this.changedAmount));
                } else {
                    this.$emit('playerAmount', null);
                }
            },
        }
    }
</script>

<style scoped></style>
